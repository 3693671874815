import 'core-js/actual';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styleOptions.css';
import App from './App';

import migrate from './migrations.js';

if (window.location.hostname === 'local.lieuwe.xyz') {
	const url = window.location.href.replace('http://local.lieuwe.xyz:6070/', 'https://streams.lieuwe.xyz/');
	window.location.replace(url);
}

window.addEventListener('unhandledrejection', e => {
	console.info('caught promise error', e);
	fetch('https://streams.lieuwe.xyz/api/web_error', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			user_agent: window.navigator.userAgent,
			message: 'unhandled promise rejection',
			error: e.reason,
		}),
	});
});

window.addEventListener('error', e => {
	console.info('caught error', e);
	fetch('https://streams.lieuwe.xyz/api/web_error', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			user_agent: window.navigator.userAgent,
			message: e.message,
			filename: e.filename,
			lineno: e.lineno,
			colno: e.colno,
			error: e.error,
		}),
	});
});

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidCatch(error, info) {
		console.info('caught react error', error, info);
		fetch('https://streams.lieuwe.xyz/api/web_error', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				user_agent: window.navigator.userAgent,
				message: 'unhandled React error',
				error: { error, info },
			}),
		});
	}

	render() {
		return this.props.children;
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
);

if (window.requestIdleCallback == null) {
	window.requestIdleCallback = fn => {
		window.setTimeout(fn, 0);
	};
}

window.requestIdleCallback(() => {
	migrate().catch(e => console.error(e));
});

window.requestIdleCallback(() => {
	const json = JSON.parse(localStorage.getItem('styleOptions') || '[]');
	for (const className of json) {
		document.body.classList.add(className);
	}
})
